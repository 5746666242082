<template>
    <div class="page-content">
        <div id="wrapper">
            <div class="course-layouts" v-if="videoData.length > 0">
                <div class="course-content bg-dark">
                    <!-- <div class="course-header">
                        <a href="#" class="btn-back" uk-toggle="target: .course-layouts; cls: course-sidebar-collapse" uk-tooltip="title: Fullscreen View ; pos:  top-right" title="" aria-expanded="false">
                            <i class="icon-feather-chevron-left"></i>
                        </a>
                        <h4 class="text-white article-tile"> </h4>
                        <div>
                            <a href="#" aria-expanded="false">
                            <i class="icon-feather-help-circle btns"></i> </a>
                            <div uk-drop="pos: bottom-right;mode : click" class="uk-drop">
                                <div class="uk-card-default p-4">
                                    <p class="mt-2 mb-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                                    diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
                                    volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                                    suscipit lobortis nisl ut aliquip ex ea commodo consequat. Nam liber tempor cum
                                    soluta nobis eleifend option congue nihil imperdiet
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="course-content-inner">
                        <video-player  class="video-player-box"
                            ref="videoPlayer"
                            :options="playerOptions"
                            :playsinline="true"
                            customEventName="customstatechangedeventname"
                        >
                        </video-player>
                    </div>
                </div>
                <!-- course sidebar -->
                <div class="course-sidebar">
                    <div class="course-sidebar-title">
                        <h3> Course Topics </h3>
                    </div>
                    <div class="course-sidebar-container" data-simplebar="init">
                        <div class="simplebar-wrapper" style="">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="">
                                    <div class="simplebar-content" >
                                    
                                        <div class="accordion" id="accordionExample">
                                            <div v-for="(item, indx) of videoData" :key="indx">
                                                <div class="card-header" :id="`headingOne_${indx}`">
                                                    <h2 class="mb-0">
                                                        <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="`#collapseOne_${indx}`" aria-expanded="true" :aria-controls="`collapseOne_${indx}`">
                                                            {{ item.topic }}
                                                        </button>
                                                    </h2>
                                                </div>

                                                <div :id="`collapseOne_${indx}`" class="collapse" :class="indx < 1 ? 'show': '' " :aria-labelledby="`headingOne_${indx}`" data-parent="#accordionExample">
                                                    <ul class="course-video-list highlight-watched">
                                                        <li id="playlist_4" data-id="LmIsbzt-S_E" class="" v-for="(video, index) of item.videos" :key="index"> 
                                                            <a href="" @click.prevent="playVideo(video.video_link)"> <strong>{{ video.title }}</strong> <span> {{ video.duration }}</span></a> 
                                                        </li>                                                        
                                                    </ul>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
            <NoRecord v-else />
        </div>
    </div>
</template>

<style>
.video-player.video-player-box .video-js{
    width: 100% !important;
    height: auto;
}
.footer{margin-top: 0;}
.page-content{padding-bottom: 0;}
.video-js .vjs-tech {
    position: relative;
}
</style>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import 'bootstrap';
import NoRecord from '../NoRecord'

export default {
    components: { videoPlayer, NoRecord },
    data() {
        return {
            playerOptions: {
                //height: 'auto',
                //width: '100',
                muted: false,
                autoplay: true,
				controls: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources:[],
                responsive: true,
                breakpoints: {
                    tiny: 300,
                    xsmall: 400,
                    small: 500,
                    medium: 600,
                    large: 700,
                    xlarge: 800,
                    huge: 900
                }
              
                //poster: "/images/no_image.jpg",
            },
            videoData: [],
            courseId: Number(this.$route.params.id)
        }
    },
    mounted(){
        this.courseVideos()
    },
    methods: {
        playVideo(video){
            this.playerOptions.sources = [{
                type: "video/mp4",
                src: video,
            }]
            //console.log(this.playerOptions.sources)
        },
        courseVideos(){
            this.$http.get(`course/${this.courseId}/video`).then(res=> {
                if(res.data.status === true) {
                    this.videoData = res.data.data
                    this.videoData.every(item=>{
                        if(item.videos) {
                            item.videos.every(v=>{
                                if(v.video_link) {
                                    this.playerOptions.sources = [{
                                        type: "video/mp4",
                                        src: v.video_link,
                                    }]
                                    return false;
                                }
                            })
                            return false;
                        }
                    })
                }
            })
            .catch(err=> {
                console.log(err.response.statusText)
            })
        }
    }
}
</script>